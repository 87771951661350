<template>
    <div class="big-card">
      <div class="layout-container">
        <!-- Benefits Section -->
        <div class="benefits-section">
  <h2 class="heading">Why Psychics Thrive on Open Psychic</h2>
  <p class="intro">
    Join the Open Psychic community and unlock a world of opportunities to grow your practice, connect with clients, and deepen your spiritual impact. As a member, you’ll receive exclusive tools and features designed to support and elevate your work, helping you become a sought-after psychic advisor.
  </p>

  <div class="benefits-list">
    <div class="benefit" v-for="benefit in benefits" :key="benefit.id">
    
      <div class="benefit-text">
        <h3 class="benefit-title">{{ benefit.title }}</h3>
        <p class="benefit-description">{{ benefit.description }}</p>
      </div>
    </div>
  </div>
</div>
  
        <!-- Payment Section -->
        <div class="payment-container">
          <h1>Complete Your Payment</h1>
          <form @submit.prevent="handlePayment" class="payment-form">
            <div class="form-group">
              <label for="name">Full Name</label>
              <input type="text" id="name" placeholder="John Doe" required />
            </div>
            <div class="form-group">
              <label for="address">Address</label>
              <input type="text" id="address" placeholder="123 Main St" required />
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <input type="text" id="city" placeholder="City Name" required />
            </div>
            <div class="form-group">
              <label for="zip">ZIP Code</label>
              <input type="text" id="zip" placeholder="12345" required />
            </div>
            <div id="card-element" class="card-input"></div>
            <button type="submit" :disabled="loading" class="pay-button">
              <span v-if="loading">Processing...</span>
              <span v-else>Pay Now</span>
            </button>
          </form>
          <p v-if="message" class="payment-message">{{ message }}</p>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { loadStripe } from "@stripe/stripe-js";
  import { defineComponent, ref, onMounted } from "vue";
  import { auth } from '@/firebase'; // Adjust import based on your setup
  import { createUserWithEmailAndPassword } from 'firebase/auth';
  import { db, setDoc, doc } from 'firebase/firestore';
  import { useStore } from 'vuex';  // Use Vuex store directly in setup()
  import { useRouter } from "vue-router";

  export default defineComponent({
    name: "PaymentPage",
    setup() {
      const store = useStore();  // Access the Vuex store
      const stripe = ref(null);
      const cardElement = ref(null);
      const message = ref("");
      const loading = ref(false);
      const router = useRouter();
      const email = ref(store.getters.getUserData?.email || "");
      const password = ref(store.getters.getUserPass || "");
  
      onMounted(async () => {
        // Initialize Stripe.js
        stripe.value = await loadStripe("pk_live_51QKSz3J1NhX9kaJ0eGoIERh0iBQsqjNC9WvOvwUhnec9zSwSDyZz6ELhWWldFaO6AejMGnpYcgZehswg18tcdvKz00ZfGGN7Nb");
        const elements = stripe.value.elements();
        cardElement.value = elements.create("card", { style: { base: { fontSize: "16px", color: "#32325d" } } });
        cardElement.value.mount("#card-element");
      });
  
      const registerPsychic = async () => {
        try {
          // Step 1: Create user in Firebase Authentication
          const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
          const user = userCredential.user;
  
          // Step 2: Store user data in Firestore
          await setDoc(doc(db, 'users', user.uid), store.getters.getUserData);
  
          // Step 3: Store user data in Vuex
          store.dispatch('setUserData', { ...store.getters.getUserData, userid: user.uid });
  
          // Step 4: Navigate to dashboard after registration
          console.log('Psychic created successfully:', user);
        } catch (error) {
          console.error('Error creating user:', error.message);
        }
      };
  
      const handlePayment = async () => {
        try {
          // Step 1: Create a Stripe customer
          const customerResponse = await fetch('http://localhost:3000/create-stripe-customer', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email.value }),
          });
  
          if (!customerResponse.ok) {
            throw new Error('Failed to create customer');
          }
  
          const { customerId } = await customerResponse.json();
  
          // Step 2: Initiate the registration fee payment
          const registrationResponse = await fetch('http://localhost:3000/charge-registration', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ customerId }),
          });
  
          if (!registrationResponse.ok) {
            throw new Error('Failed to create registration payment');
          }
  
          const { clientSecret: paymentClientSecret } = await registrationResponse.json();
  
          // Step 3: Confirm the payment using Stripe.js
          const { error, paymentIntent } = await stripe.value.confirmCardPayment(paymentClientSecret, {
            payment_method: {
              card: cardElement.value,
            },
          });
  
          if (error) {
            console.error("Payment failed:", error.message);
            alert(`Payment failed: ${error.message}`);
            return;
          }
  
          if (paymentIntent.status === 'succeeded') {
            console.log("Payment successful!");
            alert("Registration fee paid successfully!");
  
            // Step 4: Handle post-payment actions
            await registerPsychic(); // Register the psychic
            router.push('/dashboard');

          }
        } catch (error) {
          console.error("Error processing payment:", error.message);
          alert(`Error: ${error.message}`);
        }
      };
  
      return { message, loading, handlePayment };  // Return necessary properties to template
    },
    data() {
      return {
        benefits: [
          { id: 1, title: "Wider Client Reach", description: "Access a global audience looking for psychic services, from readings to spiritual advice." },
          { id: 2, title: "24/7 Availability", description: "Provide readings whenever you’re ready, thanks to the flexible and always-accessible platform." },
          { id: 3, title: "Seamless Communication", description: "Interact with clients through chat, calls, and video streams to offer personalized experiences." },
          { id: 4, title: "Enhanced Exposure", description: "Boost your profile visibility and connect with users who value your unique psychic abilities." },
          { id: 5, title: "Community Support", description: "Be part of a supportive network of psychics, share knowledge, and grow together." },
          { id: 6, title: "Access to Exclusive Tools", description: "Unlock tools that enhance your readings and help you connect deeper with your clients." },
          { id: 7, title: "Flexible Earnings", description: "Set your own rates and work on your own schedule to earn based on the value you provide, without restrictions." },
          { id: 8, title: "Real-Time Feedback", description: "Receive instant feedback from clients after each session, helping you improve and build your reputation in the community." }
        ]
      };
    },
  });
  </script>
  
  
  <style scoped>
  /* Big Card Styling */
  .big-card {
    margin: 16px;
    padding: 16px;
    border-radius: 12px;
    background-color: #3f1e4e; /* Dark purple background */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    color: #ECC3FF; /* Light purple text */
    height: 100vh;
    justify-content: space-between; /* Ensures proper alignment for child elements */
    flex-direction: column;
    display: flex;
    height: calc(100vh - 30px); /* Makes it stretch but keeps consistent padding */
  }
  
  /* Layout Container */
  .layout-container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  
  .benefits-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    margin-bottom: 40px;
  }
  
  .benefit {
    background-color: #3c1d4d;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
  }
  
  /* Payment Container */
  .payment-container {
    flex: 0.5;
    padding: 20px;
    background-color: #2a093a; /* Deep purple background */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    color: #ECC3FF;
  }
  
  /* Payment Form */
  .payment-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 8px;
  }
  
  .form-group label {
    font-size: 14px;
    font-weight: 700;
    color: #F2D1FF; /* Light purple for labels */
    margin-bottom: 8px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #D1A0F1;
    border-radius: 8px;
    background-color: transparent;
    color: #ECC3FF;
  }
  
  .form-group input:focus {
    border-color: #6200ea; /* Purple focus state */
    outline: none;
  }
  
  /* Button Styling */
  .pay-button {
    padding: 12px 20px;
    background-color: #ECC3FF; /* Light purple background */
    color: #2a093a; /* Dark purple text */
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s;
  }
  
  .pay-button:hover {
    background-color: #D79CFF; /* Hover effect */
    transform: scale(1.05);
  }
  
  .pay-button:disabled {
    background-color: #D1A0F1; /* Disabled state */
    cursor: not-allowed;
  }
  
  /* Payment Message */
  .payment-message {
    margin-top: 10px;
    color: #66BB6A; /* Green for success message */
    font-size: 14px;
  }
  
  /* Benefits Section */
  .benefits-section {
    flex: 1;
    padding: 30px;
    background: linear-gradient(135deg, #9b4dca, #6a1b9a); /* Purple gradient background */
    border-radius: 12px;
    color: #ECC3FF;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1s ease-in-out;
  }
  
  .benefits-section h2 {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }
  
  .benefit {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .benefit:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
  }
  
  .icon {
    font-size: 2.5em;
  }
  
  .benefit-text {
    flex-grow: 1;
  }
  
  .benefit-title {
    font-size: 1.6em;
    font-weight: bold;
  }
  
  .benefit-description {
    font-size: 1.1em;
    margin-top: 10px;
  }
  
  
  .icon-container {
    font-size: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .icon {
    font-size: 2em;
  }
  
  .benefit h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 5px;
    color: #ECC3FF;
  }
  
  .benefit p {
    font-size: 14px;
    margin: 0;
    color: #D1A0F1;
  }
  
  .cta {
    text-align: center;
    border-radius: 12px;
  }
  
  .cta-text {
    font-size: 1.5em;
    color: #ECC3FF; /* Soft purple text */
    font-weight: bold;
    display: inline-block;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .layout-container {
      flex-direction: column;
    }
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
  