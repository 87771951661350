<template>
    <div class="messages-container">
      <!-- Title Section -->
      <h1 class="messages-title">Messages</h1>
  
      <!-- Messages List Section -->
      <div v-if="messages.length > 0" class="messages-list">
        <ul>
          <li v-for="(message, index) in messages" :key="index" class="message-item">
            <p>{{ message.content }}</p>
          </li>
        </ul>
      </div>
  
      <!-- Empty Messages View -->
      <div v-else class="empty-messages">
        <p>No messages found.</p>
      </div>
  
      <!-- Loading Indicator -->
      <div v-if="loading" class="loading-indicator">Loading...</div>
    </div>
  </template>
  
  <script>
import { ref, onMounted, watchEffect } from 'vue';
import { getFirestore, collection, getDocs, query } from 'firebase/firestore';
import { useFirebaseAuth } from 'vuefire'; // Make sure you're using this import

export default {
  setup() {
    const messages = ref([]);
    const loading = ref(true);
    const db = getFirestore();
    const auth = useFirebaseAuth();  // Get Firebase authentication instance
    const user = auth ? auth.user : null;  // Get the user object directly

    // Check if user exists
    console.log('Auth user:', user);

    const fetchMessages = async (userId) => {
      try {
        const messagesRef = collection(db, 'users', userId, 'messages');
        const q = query(messagesRef);
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          messages.value = [];
        } else {
          messages.value = querySnapshot.docs.map(doc => doc.data());
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        loading.value = false;
      }
    };

    // Watch for changes in user authentication state
    watchEffect(() => {
      if (user) {
        loading.value = true;
        fetchMessages(user.uid);  // Fetch messages if user is authenticated
      } else {
        console.log('User is not authenticated or not yet loaded');
        messages.value = [];  // No messages if user is not authenticated
        loading.value = false;
      }
    });

        onMounted(() => {
      if (user) {
        loading.value = true;
        fetchMessages(user.uid); // Fetch messages if user is authenticated
      } else {
        console.log('User is not authenticated');
        messages.value = [];  // No messages if user is not authenticated
        loading.value = false;
      }
    });

    return {
      messages,
      loading,
    };
  },
};
  </script>
  
  <style scoped>
  .messages-container {
    padding: 16px;
    background-color: #8e44ad; /* Purple background color */
  }
  
  .messages-title {
    font-size: 18px;
    color: #e0b0d7; /* Accent color */
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .messages-list {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  
  .message-item {
    background-color: white;
    margin: 4px 0;
    padding: 10px;
    border-radius: 8px;
  }
  
  .empty-messages {
    color: #e0b0d7; /* Accent color */
    font-size: 18px;
    font-style: italic;
  }
  
  .loading-indicator {
    text-align: center;
    color: white;
    font-size: 18px;
    padding: 10px;
  }
  </style>
  