<template>
  <div class="big-card">
    <div class="layout-container">
      <!-- Benefits Section -->
  <div class="benefits-section">
    <h2 class="heading">Unlock the Full Potential of Open Psychic Premium</h2>
    <p class="intro">
      Transform your spiritual journey with Open Psychic Premium for just $9.99/month. Whether you're seeking deep insights, personalized readings, or holistic guidance, our Premium membership opens up a world of possibilities, giving you the tools to enhance your life—spiritually, mentally, and emotionally.
    </p>

    <div class="benefits-list">
      <div class="benefit" v-for="benefit in benefits" :key="benefit.id">
      
        <div class="benefit-text">
          <h3 class="benefit-title">{{ benefit.title }}</h3>
          <p class="benefit-description">{{ benefit.description }}</p>
        </div>
      </div>
    </div>

    <div class="cta">
  <span class="cta-text">Join Now & Unlock Your Potential</span>
</div>
  </div>
      <!-- Payment Section -->
      <div class="payment-container">
        <h1>Complete Your Payment</h1>
        <div class="order-details">
    <!-- Open Psychic Premium Description -->
    <div class="order-summary">
      <h2>Premium</h2>
      <p>$9.99/month</p>
    </div>
    <div class="divider"></div>

    <!-- Subscription Benefits -->
    <div class="subscription-benefits">
      <h3>By subscribing to Open Psychic Premium, you unlock:</h3>
      <ul>
        <li>Unlimited psychic readings and messages.</li>
        <li>Exclusive access to premium content and features.</li>
        <li>Priority support and personalized insights.</li>
        <li>Enhanced features to amplify your spiritual journey.</li>
      </ul>
    </div>

    <div class="divider"></div>

    <!-- Order Summary: Subtotal, Tax, and Total -->
    <div class="order-summary">
      <h3>Order Summary</h3>
      <ul>
        <li>Subtotal: $9.99</li>
        <li>Tax: $0.00</li>
        <li><strong>Total: $9.99</strong></li>
      </ul>
    </div>
    <div class="divider"></div>
  </div>

        <form @submit.prevent="handlePayment" class="payment-form">
          <div class="form-group">
            <label for="name">Full Name</label>
            <input type="text" id="name" placeholder="John Doe" required />
          </div>
          <div class="form-group">
            <label for="address">Address</label>
            <input type="text" id="address" placeholder="123 Main St" required />
          </div>
          <div class="form-group">
            <label for="city">City</label>
            <input type="text" id="city" placeholder="City Name" required />
          </div>
          <div class="form-group">
            <label for="zip">ZIP Code</label>
            <input type="text" id="zip" placeholder="12345" required />
          </div>
          <div id="card-element" class="card-input"></div>
          <button type="submit" :disabled="loading" class="pay-button">
            <span v-if="loading">Processing...</span>
            <span v-else>Pay Now</span>
          </button>
        </form>
        <p v-if="message" class="payment-message">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { auth } from "@/firebase"; // Adjust import based on your Firebase setup
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getApp } from "firebase/app";

export default defineComponent({
  name: "PaymentPage",
  setup() {
    const stripe = ref(null);
    const cardElement = ref(null);
    const clientSecret = ref(null);
    const message = ref("");
    const loading = ref(false);
    const store = useStore();
    const app = getApp();
    const db = getFirestore(app);
    const router = useRouter();

    // Initialize Stripe and the card element
    onMounted(async () => {
      try {
        stripe.value = await loadStripe("pk_test_51QKSz3J1NhX9kaJ0uyOaYygHxePrx8AyhGXDqdGBCjOsuvLwmbpL6fk9z64oleGCvIgH3187XxWyvdgbzqr1MWWu00N4k5boW5");
        const elements = stripe.value.elements();
        cardElement.value = elements.create("card", { style: { base: { fontSize: "16px", color: "#32325d" } } });
        cardElement.value.mount("#card-element");

        // Fetch email and password from Vuex store
        const email = store.getters.getUserData?.email;

        // Create a customer in Stripe
        const createCustomerResponse = await fetch("http://localhost:3000/create-stripe-customer", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });
        const { customerId } = await createCustomerResponse.json();

        // Fetch the client secret for the subscription
        const subscriptionResponse = await fetch("http://localhost:3000/charge-subscription", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ customerId }),
        });
        const { clientSecret: fetchedClientSecret } = await subscriptionResponse.json();
        clientSecret.value = fetchedClientSecret;
      } catch (error) {
        console.error("Initialization error:", error);
        message.value = "Failed to initialize payment process. Please try again.";
      }
    });

    // Handle Payment Confirmation
    const handlePayment = async () => {
      loading.value = true;
      message.value = "";

      try {
        const { error, paymentIntent } = await stripe.value.confirmCardPayment(clientSecret.value, {
          payment_method: {
            card: cardElement.value,
          },
        });

        if (error) {
          message.value = `Payment failed: ${error.message}`;
          return;
        }

        if (paymentIntent.status === "succeeded") {
          message.value = "Subscription successful!";

          // Firebase Authentication and Firestore Logic
          const email = store.getters.getUserData?.email;
          const password = store.getters.getUserPass;
          const userData = store.getters.getUserData;

          try {
            // Create Firebase user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Store user data in Firestore
            const completeUserData = { ...userData, userid: user.uid };
            await setDoc(doc(db, "testusers", user.uid), completeUserData);

            // Update Vuex store and redirect
            store.dispatch("setUserData", completeUserData);
            router.push("/dashboard");
          } catch (firebaseError) {
            console.error("Error creating user in Firebase:", firebaseError);
            message.value = "Failed to complete registration. Please contact support.";
          }
        }
      } catch (paymentError) {
        console.error("Payment error:", paymentError);
        message.value = "An unexpected error occurred. Please try again.";
      } finally {
        loading.value = false;
      }
    };

    return { message, loading, handlePayment };
  },
  data() {
    return {
      benefits: [
        {
          id: 1,
          icon: require('@/assets/magic_wand.png'),
          title: '✨ Priority Access to Top-Rated Psychics',
          description: 'Skip the wait and connect instantly with the most experienced and highly-rated psychics. With Premium, you get priority access to the best advisors on the platform, ensuring your spiritual journey is in expert hands every time.'
        },
        {
          id: 2,
          icon: require('@/assets/magic_wand.png'),
          title: '🌍 Global Access to Psychic Advisors',
          description: 'Open Psychic Premium connects you with a diverse network of psychics from around the world, specializing in clairvoyance, tarot readings, astrology, mediumship, and more. Connect with the right advisor, no matter where you are.'
        },
        {
          id: 3,
          icon: require('@/assets/magic_wand.png'),
          title: '💬 Unlimited Private Messaging',
          description: 'Enjoy unlimited, free private messaging with any psychic advisor. Whether you'
        },
        {
          id: 4,
          icon: require('@/assets/magic_wand.png'),
          title: '💰 Discounted Standard Pricing for Psychic Services',
          description: 'As a Premium member, enjoy special discounted pricing on video readings, phone consultations, and text-based messages, without compromising on quality.'
        },
        {
          id: 5,
          icon: require('@/assets/magic_wand.png'),
          title: '📥 $15 Downloadable Readings',
          description: 'Download your personal psychic readings directly to your device for just $15. Conveniently access tarot readings, astrological forecasts, or personal reflections anytime, anywhere.'
        },
        {
          id: 6,
          icon: require('@/assets/magic_wand.png'),
          title: '🎧 $10 Audible Readings',
          description: 'Enjoy personalized psychic readings in audio format for only $10. Listen to your insights while driving, relaxing, or meditating, bringing the wisdom of psychics wherever you go.'
        }
      ]
    };
  }
  }
    );
</script>
<style scoped>

/* Big Card Styling */
.big-card {
  overflow: hidden; /* Hide the scrollbar */
  overflow-y: auto; /* Allow vertical scrolling */
  margin: 16px;
  padding: 16px;
  border-radius: 12px;
  background-color: #3f1e4e; /* Dark purple background */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  color: #ECC3FF; /* Light purple text */
  height: 100vh;
  justify-content: space-between; /* Ensures proper alignment for child elements */
  flex-direction: column;
  display: flex;
  height: calc(100vh - 30px); /* Makes it stretch but keeps consistent padding */
}

/* Layout Container */
.layout-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.benefits-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
}

.benefit {
  background-color: #3c1d4d;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

/* Payment Container */
.payment-container {
  flex: 0.5;
  padding: 20px;
  background-color: #2a093a; /* Deep purple background */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: #ECC3FF;
}

/* Payment Form */
.payment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 8px;
}

.form-group label {
  font-size: 14px;
  font-weight: 700;
  color: #F2D1FF; /* Light purple for labels */
  margin-bottom: 8px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #D1A0F1;
  border-radius: 8px;
  background-color: transparent;
  color: #ECC3FF;
}

.form-group input:focus {
  border-color: #6200ea; /* Purple focus state */
  outline: none;
}

/* Button Styling */
.pay-button {
  padding: 12px 20px;
  background-color: #ECC3FF; /* Light purple background */
  color: #2a093a; /* Dark purple text */
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s;
}

.pay-button:hover {
  background-color: #D79CFF; /* Hover effect */
  transform: scale(1.05);
}

.pay-button:disabled {
  background-color: #D1A0F1; /* Disabled state */
  cursor: not-allowed;
}

/* Payment Message */
.payment-message {
  margin-top: 10px;
  color: #66BB6A; /* Green for success message */
  font-size: 14px;
}

/* Benefits Section */
.benefits-section {
  flex: 1;
  padding: 30px;
  background: linear-gradient(135deg, #9b4dca, #6a1b9a); /* Purple gradient background */
  border-radius: 12px;
  color: #ECC3FF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: fadeIn 1s ease-in-out;
}

.benefits-section h2 {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}

.benefit {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
}

.icon {
  font-size: 2.5em;
}

.benefit-text {
  flex-grow: 1;
}

.benefit-title {
  font-size: 1.6em;
  font-weight: bold;
}

.benefit-description {
  font-size: 1.1em;
  margin-top: 10px;
}


.icon-container {
  font-size: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 2em;
}

.benefit h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 5px;
  color: #ECC3FF;
}

.benefit p {
  font-size: 14px;
  margin: 0;
  color: #D1A0F1;
}

.cta {
  text-align: center;
  border-radius: 12px;
}

.cta-text {
  font-size: 1.5em;
  color: #ECC3FF; /* Soft purple text */
  font-weight: bold;
  display: inline-block;
}

.big-card::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

/* Responsive Design */
@media (max-width: 768px) {
  .layout-container {
    flex-direction: column;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #8e44ad 0%, #9b59b6 100%);
  margin: 20px 0; /* Adjust margin for spacing */
}

.order-summary {
  background-color: #1d0c1d; /* Dark background for contrast */
  padding: 16px;
  border-radius: 8px; /* Soft corners for a modern look */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.order-summary h3 {
  font-size: 1.5em;
  color: #9b59b6; /* Purple color for the heading */
  margin-bottom: 15px; /* Space between title and list */
  text-align: center;
}

.order-summary ul {
  list-style: none; /* Remove default list style */
  padding: 0;
  margin: 0;
}

.order-summary ul li {
  display: flex;
  justify-content: space-between; /* Space out the label and price */
  padding: 8px 0;
  font-size: 1.1em;
  color: #fff; /* White text for readability */
  border-bottom: 1px solid #9b59b6; /* Light purple border */
}

.order-summary ul li.total {
  font-weight: bold;
  font-size: 1.2em;
  color: #9b59b6; /* Purple color for the total */
  border-bottom: none; /* Remove the bottom border for the total item */
}

.order-summary span {
  display: inline-block;
}

.price {
  color: #8e44ad; /* Purple color for the price */
  font-weight: bold;
}

.order-summary ul li:last-child {
  border-bottom: none; /* Remove the bottom border from the last item */
}
</style>
