// main.js

import { createApp } from 'vue'; // Import createApp from Vue
import App from './App.vue'; // Import your main App component
import router from './router'; // Import the router instance
import { VueFire, VueFireAuth } from 'vuefire'; // Import VueFire and VueFireAuth for Firebase integration
import { auth, firestore } from './firebase'; // Import your Firebase auth and firestore instances
import store from './store.js';

// Initialize the Vue app
const app = createApp(App);
app.use(store); // Register Vuex store

// Make Firebase services available in all components
app.config.globalProperties.$auth = auth;
app.config.globalProperties.$firestore = firestore;

// Set up Firebase Auth and Firestore using VueFire
app.use(VueFire, {
  auth,        // Using Firebase Auth
  firestore,   // Using Firebase Firestore
  modules: [VueFireAuth]  // Include VueFireAuth module for Firebase Authentication
});

// Use the router instance with the app
app.use(router);

// Mount the app to the DOM
app.mount('#app');
