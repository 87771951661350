<template>
  <div :class="['sidebar', { collapsed: isCollapsed, 'is-mobile': isMobile }]">
    <div class="sidebar-logo" @click="navigate('/')">
      <img src="@/assets/openpsychiclogo.png" alt="Logo" />
      <span v-if="!isCollapsed || !isMobile" class="logo-text">Open Psychic</span>
    </div>
    <nav class="nav-menu">
      <ul>
        <li v-for="item in menuItems" :key="item.label" @click="navigate(item.route)">
          <img :src="item.icon" alt="Icon" class="nav-icon" />
          <span v-if="!isCollapsed || !isMobile" class="nav-label">{{ item.label }}</span>
        </li>
      </ul>
    </nav>
    <button class="toggle-button" @click="toggleCollapse">
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapsed: false,
      isMobile: window.innerWidth <= 768,
      menuItems: [
        // { label: 'Home', route: '/', icon: require('@/assets/home-agreement.png') },
        { label: 'Explore', route: '/explore', icon: require('@/assets/003-menu-button.png') },
        { label: 'Live', route: '/live', icon: require('@/assets/graph-bars-icon.png') },
        { label: 'Wallet', route: '/wallet', icon: require('@/assets/wallet-buyer.png') },
        // { label: 'Request', route: '/request', icon: require('@/assets/request-icon.png') },
        // { label: 'Notifications', route: '/notifications', icon: require('@/assets/notification-icon.png') },
        // { label: 'Profile', route: '/profile', icon: require('@/assets/profile-icon.png') },
        { label: 'Settings', route: '/settings', icon: require('@/assets/001-setting-bulb.png') },
      ],
    };
  },
  methods: {
    navigate(route) {
      if (route === '/dashboard' || route === '/explore') {
        window.location.reload();
      } else {
        this.$router.push(route);
        this.$router.replace(route);
      }
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      this.$emit('collapsed', this.isCollapsed);
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 200px;
  height: 100vh;
  background-color: #16041E;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px #DB8FFF;
  border-right: 2px solid #DB8FFF;
  z-index: 1000;
  transition: width 0.3s;
  overflow-x: hidden; /* Prevents unwanted horizontal scrolling */
  overflow-y: hidden; /* Prevents unwanted vertical scrolling */


}

.collapsed {
  width: 70px;
}

.is-mobile {
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  top: auto;
  flex-direction: row;
  overflow-x: auto; /* Enable horizontal scroll for mobile */
  white-space: nowrap; /* Prevents items from wrapping */

}

.sidebar-logo {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.sidebar-logo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.logo-text {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}

.nav-menu {
  margin-top: 30px;
  flex-grow: 1;
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.is-mobile .nav-menu ul {
  flex-direction: row;
  justify-content: space-around;
}

.nav-menu li {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-menu li:hover {
  background-color: #50108a;
}

.nav-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.nav-label {
  font-size: 16px;
}

.is-mobile .nav-label {
  display: none;
}

.toggle-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar {
    height: 60px;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .collapsed {
    width: 100%;
  }

  .nav-menu {
    margin-top: 0;
    flex-direction: row;
  }

  .logo-text {
    display: none;
  }
}
</style>
