// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '@/components/DashboardModel.vue'; // Correct import path and naming
import GoLiveModule from '../components/GoLiveModule.vue';
import LivePsychicModule from '../components/LivePsychicModule.vue';
import UserSubscriptionPayment from '../components/UserSubscriptionPayment.vue';
import MessagesModule from '../components/MessagesModule.vue';
import SettingsModule from '../components/SettingsModule.vue';
import PsychicRegistrationPayment from '../components/PsychicRegistrationPayment.vue';

const routes = [
  { path: '/',  component: Dashboard }, // Redirect to Dashboard by default    
  { path: '/explore', component: Dashboard }, // Redirect to Dashboard by default    
  { path: '/dashboard', component: Dashboard }, // Route for the Dashboard page
  { path: '/live', component: LivePsychicModule }, // Route for the Live Psychics page
  { path: '/subscription', component: UserSubscriptionPayment }, // Route for the Live Psychics page
  { path: '/registration', component: PsychicRegistrationPayment }, // Route for the Live Psychics page
  { path: '/go-live', name: 'GoLive', component: GoLiveModule,}, //meta: { requiresAuth: true }} // Ensure only authorized users can access
  { path: '/messages', component: MessagesModule,},//meta: { requiresAuth: true }} // Ensure only authorized users can access
  { path: '/settings', component: SettingsModule,} //meta: { requiresAuth: true }} // Ensure only authorized users can access
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
