// store.js
import Vuex from 'vuex';

export default new Vuex.Store({

    state: {
      userData: null,
      userPass: null,
    },
    mutations: {
      setUserData(state, userData) {
        state.userData = userData;
      },
      setUserPass(state, userPass) {
        state.userPass = userPass;
      },
    },
    actions: {
      setUserData({ commit }, userData) {
        commit('setUserData', userData);
      },
      setUserPass({ commit }, userPass) {
        commit('setUserPass', userPass);
      },
    },
    getters: {
      getUserData: (state) => state.userData,
      getUserPass: (state) => state.userPass,
    },
  });
  