<template>
  <div class="contact-card">
    <!-- Contact Header -->
    <h3>Contact</h3>

    <!-- Call-to-Action Buttons -->
    <button @click="startLiveCall" class="contact-btn call-btn">Start Live 1 on 1 Video Chat</button>
    <VideoCall v-if="isVideoCallActive" @endCall="endLiveCall" />
    <button @click="onMessage" class="contact-btn message-btn">Start a chat session</button>
    <button @click="onRequest" class="contact-btn request-btn">Request A Reading</button>

    <!-- Divider Line -->
    <hr class="divider" />

    <!-- Extra Buttons -->
    <button @click="onSubscribe" class="contact-btn subscribe-btn">Subscribe</button>
    <button @click="onTip" class="contact-btn tip-btn">Tip</button>
  </div>
</template>

<script>
import VideoCall from './VideoCall.vue';

export default {
  components: {
    VideoCall,
  },
  props: {
    psychic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isVideoCallActive: false, // Track video call state
    };
  },
  methods: {
    startLiveCall() {
      this.isVideoCallActive = true;
    },
    endLiveCall() {
      this.isVideoCallActive = false;
    },
    onMessage() {
      // Message action logic
    },
    onRequest() {
      // Request action logic
    },
    onSubscribe() {
      // Subscribe action logic
    },
    onTip() {
      // Tip action logic
    },
  },
};
</script>

<style scoped>
.contact-card {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.contact-card h3 {
  font-size: 1.8em;
  color: #ECC3FF;
  margin-bottom: 20px;
}

.contact-btn {
  color:aliceblue;
  background-color: #16041E ;
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
}

.contact-btn:hover {
  transform: scale(1.02);
  opacity: 0.9;
}

.divider {
  width: 90%;
  height: 2px;
  background-color: #6a0dad;
  margin: 20px 0;
}
</style>
