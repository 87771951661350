<template>
    <div class="settings-page">
      <!-- Header -->
      <div class="settings-header">
        <h1>Settings</h1>
        
      </div>
  
      <!-- Tabs -->
      <div class="settings-tabs">
        <button
          v-for="tab in tabs"
          :key="tab"
          :class="{ active: activeTab === tab }"
          @click="activeTab = tab"
        >
          {{ tab }}
        </button>
      </div>
  
      <div v-if="activeTab === 'Account'" class="settings-section">
      <!-- Account Eligibility Card -->
      <div class="card">
        <h3>Account Eligibility</h3>
        <div class="form-group">
          <p>Your account is in good standing.</p>
          <div class="status-icon">
            <span class="status-icon-good"></span>
            <p>Account Status: Active</p>
          </div>
        </div>
        
      </div>
            <!-- Sign Out Card -->
            <div class="card">
        <h3>Sign Out</h3>
        <div class="form-group">
          <p>Are you sure you want to sign out?</p>
          <button class="btn danger" @click="signOut">Sign Out</button>
        </div>
      </div>
    </div>
      <!-- Profile Section -->
      <div v-if="activeTab === 'Profile'" class="settings-section">
        <div class="card">
        <h2>Profile</h2>
        <div class="form-group">
          <label>Your Photo</label>
          <div class="profile-photo">
            <img :src="profile.photo" alt="Profile Photo" />
            <div>
              <button class="btn-link">Update</button>
              <button class="btn-link danger">Delete</button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="username">Username</label>
          <div class="input-with-prefix">
            <span>untitledui.com/</span>
            <input
              id="username"
              type="text"
              v-model="profile.username"
              placeholder="Username"
            />
          </div>
        </div>
  
        <div class="form-group">
          <label for="website">Website</label>
          <input
            id="website"
            type="text"
            v-model="profile.website"
            placeholder="http://www.example.com"
          />
        </div>
  
        <div class="form-group">
          <label for="bio">Your Bio</label>
          <textarea
            id="bio"
            v-model="profile.bio"
            placeholder="Write a short introduction..."
            rows="4"
          ></textarea>
          <p class="char-count">{{ 275 - profile.bio.length }} characters left</p>
        </div>
  
        <div class="form-group">
          <label for="job-title">Job Title</label>
          <input
            id="job-title"
            type="text"
            v-model="profile.jobTitle"
            placeholder="e.g. Product Designer"
          />
          <div class="checkbox">
            <input
              id="show-job-title"
              type="checkbox"
              v-model="profile.showJobTitle"
            />
            <label for="show-job-title">Show job title in my profile</label>
          </div>
        </div>
  
        <div class="form-group">
          <label for="alt-email">Alternative Contact Email</label>
          <input
            id="alt-email"
            type="email"
            v-model="profile.altEmail"
            placeholder="example@example.com"
          />
        </div>
      </div>
    </div>
       <!-- Profile Section -->
       <div v-if="activeTab === 'Plan'" class="settings-section">
        <div class="card">
        <h2>Membership</h2>
        <div class="form-group">
            <p><strong>Status:</strong> Free Plan</p>
            <p>Enjoy all the basic features without any charges.</p>
        </div>
      </div>
    </div>
           <!-- Profile Section -->
           <div v-if="activeTab === 'Email Notifications'" class="settings-section">
            <div class="card">
        <h2>Email Subscription Preferences</h2>
        <div class="form-group">
          <label>
              Product News and Updates
          </label>
          <p>Get notified about new and improved features, product deep dives, and more.</p>
        </div>
    <!-- Events and Experiences -->
    <div class="form-group">
      <label>
        Events and Experiences
      </label>
      <p>Receive the latest updates on our workshops, in-person events, virtual events, and other creator-focused experiences.</p>
    </div>

    <!-- Community and Education -->
    <div class="form-group">
      <label>
        Community and Education
      </label>
      <p>Get updates on programs and trending conversations in our Creator Community Discord.</p>
    </div>

    <!-- User & Market Research -->
    <div class="form-group">
      <label>
        User & Market Research
      </label>
      <p>Receive emails about opportunities to take part in user research and surveys to help improve the product.</p>
    </div>

    <!-- Marketing and Promotions -->
    <div class="form-group">
      <label>
        Marketing and Promotions
      </label>
      <p>Receive general marketing and promotional emails from Open Psychic.</p>
    </div>

    <!-- Creator Hub -->
    <div class="form-group">
      <label>
        Creator Hub
      </label>
      <p>Your boost of inspiration and know-how, featuring creator stories, entrepreneurship advice, and invitations to workshops and events.</p>
    </div>

    <!-- Email Notifications Reminders -->
    <div class="form-group">
      <label>
        Email Notifications
      </label>
      <p>Stay up to date with email reminders about your page.</p>
    </div>

  <!-- Unsubscribe from all marketing emails -->
  <div class="form-group">
      <label>
        Unsubscribe from all marketing emails
      </label>
    </div>

    <div class="button-container">
  <button @click="updatePreferences" class="update-btn">Update</button>
</div>

  </div>
</div>

</div>
  </template>
  
  <script>

  export default {
    methods:{
      async signOut() {
      try {
        // If you're using Firebase authentication
        await this.$auth().signOut();
        
        // Optionally, clear user-related data in Vuex or elsewhere
        // this.$store.commit('SET_USER', null);

        // Redirect user to login page or home page after signing out
        this.$router.push('/');
        
      } catch (error) {
        console.error("Error signing out: ", error);
        alert("Something went wrong while signing out.");
      }
    }
    },
    data() {
      return {
        emailPreferences: {
          productNews: false,
          eventsExperiences: false,
          communityEducation: false,
          userResearch: false,
          marketingPromotions: false,
          unsubscribeAll: false,
          creatorHub: false,
          pageReminders: false
       },
        search: "",
        tabs: [
          "Account",
          "Profile",
          "Plan",
          "Email Notifications",
        ],
        activeTab: "Profile",
        profile: {
          username: "olivia",
          website: "http://www.untitledui.com",
          photo: "https://via.placeholder.com/80",
          bio: "I'm a Product Designer based in Melbourne, Australia. I specialize in UX/UI design, brand strategy, and Webflow development.",
          jobTitle: "Product Designer",
          showJobTitle: true,
          altEmail: "example@example.com",
        },
      };
    },
  };
  </script>
  <style scoped>
  /* Layout */
  .settings-page {
    font-family: 'Inter', sans-serif;
    margin: 8px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px;
    background-color: #2a093a; /* Deep purple background */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    color: #ECC3FF; /* Light purple text */
  }

  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .settings-header h1 {
    font-size: 28px;
    font-weight: 700;
    color: #ECC3FF;
  }

  /* Tabs */
  .settings-tabs {
    display: flex;
    gap: 12px;
    margin: 20px 0;
  }

  .settings-tabs button {
    padding: 12px 18px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    background: #F2D1FF; /* Light purple button */
    color: #2a093a; /* Dark purple text */
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }

  .settings-tabs button.active {
    background: #6200ea; /* Strong purple for active button */
    color: #ECC3FF; /* Light purple text for active state */
    font-weight: bold;
  }

  .settings-tabs button:hover {
    background: #D1A0F1; /* Light hover effect */
  }


  /* Section */
  .settings-section {
    margin-top: 20px;
  }

  .settings-section h2 {
    font-size: 22px;
    font-weight: 700;
    color: #ECC3FF;
    margin-bottom: 20px;
  }

  /* Form */
  .form-group {
    padding: 16px;

  }

  .form-group label {
    font-size: 14px;
    font-weight: 700;
    color: #F2D1FF;
    margin-bottom: 8px;
    display: block;
    text-align: start;
}

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #D1A0F1;
    border-radius: 8px;
    outline: none;
    transition: border 0.3s ease;
  }

  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #6200ea; /* Purple focus state */
  }

  textarea {
    resize: none;
  }

  .char-count {
    font-size: 12px;
    color: #D1A0F1;
    margin-top: 5px;
  }

  /* Profile Photo */
  .profile-photo {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .profile-photo img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #F2D1FF;
  }

  .profile-photo button {
    font-size: 14px;
    font-weight: 700;
    color: #6200ea;
    background: none;
    border: none;
    cursor: pointer;
  }

  .profile-photo button.danger {
    color: #E53935; /* Red for danger button */
  }

  /* Checkbox */
  .checkbox {
    align-items: right;
    gap: 10px;
  }

  .checkbox-container {
  align-items: center;
  justify-content: flex-start; /* Align the checkbox to the left */
  gap: 12px; /* Space between checkbox and label */
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-label {
  font-size: 16px;
  font-weight: 600;
  color: #ECC3FF; /* Light purple color */
}

.checkbox-description {
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  color: #AAA;
}
  /* Card Styles */
.card {
  background-color: #3f1e4e;
  border-radius: 10px;
  padding: 16px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 26px;
  font-weight: 700;
  color: #ECC3FF;
  text-align: left;
  margin:8px
}

.card h3 {
  font-size: 16px;
  font-weight: 700;
  color: #ECC3FF;
  text-align: left;
  margin:8px
}

.card .form-group {
  text-align: left;

}

.card .btn {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.card .btn.danger {
  background-color: #E53935;
  color: white;
}

.card .btn.danger:hover {
  background-color: #C62828;
}

.card .status-icon {
  display: flex;
  align-items: center;
}

.status-icon-good {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #66BB6A; /* Green for good status */
  margin-right: 10px;
}
.button-container {
  display: flex;
  justify-content: flex-end;  /* Aligns the button to the right */
}

.update-btn {
  background-color: #ECC3FF;  /* Light purple to match your theme */
  color: white;  /* Text color */
  border: none;
  padding: 12px 24px;  /* Padding around the text */
  font-size: 16px;  /* Font size */
  font-weight: bold;  /* Bold text */
  border-radius: 8px;  /* Rounded corners */
  cursor: pointer;  /* Show pointer on hover */
  transition: background-color 0.3s, transform 0.2s ease;  /* Smooth hover effect */
}

.update-btn:hover {
  background-color: #D79CFF;  /* Slightly darker purple on hover */
  transform: scale(1.05);  /* Slight scale effect on hover */
}

.update-btn:focus {
  outline: none;  /* Remove focus outline */
}

.update-btn:active {
  background-color: #BE86D6;  /* Even darker purple on click */
}
</style>
